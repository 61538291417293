// http://stackoverflow.com/questions/9027438/jquery-tablesorter-not-sorting-column-with-formatted-currency-value
document.addEventListener('turbo:load', function() {
  if (!document.querySelectorAll('table.ordered'))
    return;

  $('table.ordered').tablesorter({
    textExtraction: function(node) {
      return $(node).text().replace(/[,$£€]/g,'');
    }
  })
});
