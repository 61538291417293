import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["focusField", "escapeField", "escaper", "requirer", "requiree"]

  connect() {
    this.escapeFieldTargets.forEach(field => {
      if (field.dataset.action) {
        field.dataset.action += " keydown.esc->forms#escape"
      } else {
        field.dataset.action = "keydown.esc->forms#escape"
      }
    })

    this.focusField()
  }

  focusField() {
    if (!this.hasFocusFieldTarget)
      return

    const end = this.focusFieldTarget.value.length
    this.focusFieldTarget.setSelectionRange(end, end)
    this.focusFieldTarget.focus()
  }

  escape(event) {
    if (this.hasEscaperTarget) {
      this.escaperTarget.click()
    }
  }

  requireField(event) {
    if (!this.hasRequirerTarget || !this.hasRequireeTarget)
      return

    this.requireeTarget.required = true
  }

  doNotRequireField(event) {
    if (!this.hasRequirerTarget || !this.hasRequireeTarget)
      return

    this.requireeTarget.required = false
  }
}
