// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./src/jquery"
import flatpickr from 'flatpickr'
import "tablesorter"
import "./main"

import * as bootstrap from "bootstrap"

require("@rails/ujs").start()
