document.addEventListener("turbo:frame-load", () => {
  const dateInputs = document.querySelectorAll('input[data-datepicker]');
  if (!dateInputs)
    return

  dateInputs.forEach((input) => {
    input.onkeypress = () => false;
    flatpickr(input, { allowInput: true, altInput: false });
  })
})
